<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
@import '@/assets/all.scss';
// 自訂文字
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,700,900&subset=chinese-traditional');
</style>
