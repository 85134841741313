import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import 'bootstrap'
import { currency } from './methods/filters'
import App from './App.vue'
import router from './router'

// import '@/assets/plugins/AdminLTE'

import '../node_modules/admin-lte/dist/js/adminlte.min.js'

const app = createApp(App)

app.config.globalProperties.$filters = {
  currency
}

const pinia = createPinia()

app.use(pinia)
pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

app.use(router)
app.mount('#app')
