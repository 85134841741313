export function currency (num) {
  if (num !== null) {
    const isInterger = parseInt(num, 10) === num
    const n = isInterger ? parseInt(num, 10).toString() : (parseFloat(num, 10)).toFixed(2)
    return `${n
      .replace(/./g, (c, i, a) =>
        i && c !== '.' && (a.length - i) % 3 === 0
          ? `, ${c}`.replace(/\s/g, '')
          : c
      )}`
  }
}
